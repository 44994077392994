import React from 'react';
import './style.scss';

const Footer = () => {
  return (
      <footer className={'footer'}>
          <div className={'footer_wrapper container'}>
              <div className={'icons'}>
                    <div className={'icons_payment'}>
                        <h3>Platobné metódy</h3>
                        <div className={'payments-wrapper'}>
                            <span className={'payment visa'}/>
                            <span className={'payment slovenska'}/>
                            <span className={'payment vub'}/>
                            <span className={'payment postova'}/>
                            <span className={'payment tatra'}/>
                            <span className={'payment unicredit'}/>
                        </div>
                    </div>
                    <div className={'icons_media'}>
                        <h3>Komunikácia</h3>
                        <div className={'medias-wrapper'}>
                            <span className={'media email'}/>
                            <span className={'media facebook'}/>
                            <span className={'media twitter'}/>
                            <span className={'media youtube'}/>
                            <span className={'media instagram'}/>
                            <span className={'media restriction'}/>
                        </div>
                    </div>
                </div>
              <div className={'copyright'}>
                  <p className={'copyright_item highlight'}>© 2015 - 2021 FORTUNA</p>
                  <p className={'copyright_item'}>
                      <b>Zákaz hrania hazardných hier osobám mladším ako 18 rokov.</b>
                      Hazardné hry predstavujú riziko vysokých finančných strát. Nadmerné hranie prináša so sebou aj možné zdravotné riziká.</p>
              </div>
          </div>
      </footer>
  )
}

export default Footer;