import React from 'react';
import './style.scss';

const Main = () => {
  return (
      <main className={'content'}>
          <div className={'container'}>
              <h1 className={'main-title'}>
                  STAV SI VO <span className={'highlight'}>FORTUNE</span> S BONUSOM
              </h1>
              <div className={'column-section'}>
                  <div className={'columns'}>
                      <div className={'columns_item'}>
                          <h2 className={'highlight'}>
                              BONUS PRE NOVÝCH HRÁČOV
                          </h2>
                          <p>
                              Pre získanie plnej výšky vstupných bonusov je potrebný vklad na hráčsky účet v minimálnej výške 30 € a následné uzavretie stávky/stávok podľa pravidiel a podmienok k bonusom. Bonusová ponuka platí len pre nových hráčov (18+). Plné znenie pravidiel a podmienok sa zobrazí kliknutím na nižšie uvedené tlačidlo „Pravidlá a podmienky“.
                          </p>
                      </div>
                      <div className={'columns_item'}>
                          <h2 className={'highlight'}>
                              SLEDUJ TISÍCKY PRENOSOV
                          </h2>
                          <p>
                              Stav si a sleduj svoje obľúbené zápasy z futbalových líg, európskych hokejových súťaží, turnajov UFC, tenisových turnajov a ďalších športov prostredníctvom live streamov na FORTUNA TV.
                          </p>
                      </div>
                      <div className={'columns_item'}>
                          <h2 className={'highlight'}>
                              HRAJ ZODPOVEDNE
                          </h2>
                          <p>
                              Hazardné hry predstavujú riziko vysokých finančných strát a vzniku závislosti. Zákaz hrania hazardných hier osobám mladším ako 18 rokov.
                          </p>
                      </div>
                  </div>
              </div>

              <div className={'lists-section'}>
                  <div className={'lists-title'}>
                      <span className={'title-dot'} />
                      <h3>Pravidlá a podmienky</h3>
                  </div>
                  <div className={'lists-wrapper'}>
                      <div className={'list'}>
                          <h3 className={'list_title highlight'}>
                              Stávka bez rizika až do výšky 30 eur
                          </h3>
                          <ul>
                              <li>Ponuka Stávky bez rizika platí pre každého nového klienta, zaregistrovaného v stávkovej kancelárii FORTUNA SK, a.s. (ďalej Fortuna).</li>
                              <li>Stávku bez rizika môžete využiť online (na webstránke www.ifortuna.sk a v mobile na gm.ifortuna.sk), a vo všetkých pobočkách spoločnosti Fortuna.</li>
                              <li>Táto ponuka platí iba na prvú stávku po aktivovaní tohto bonusu. Bonus je aktivovaný automaticky po vyplnení a odoslaní registračného formulára.</li>
                              <li>Ponuka Stávky bez rizika platí na prvú stávku s minimálnym kurzom 2.00 (platí pre SOLO, AKO, s výnimkou Virtuálne hry).</li>
                              <li>Na uzavretie prvej stávky má klient obdobie 7 dní po aktivácii, po tejto dobe nárok na uzavretie Stávky bez rizika zaniká.</li>
                              <li>V prípade, ak prvá stávka po aktivácii bonusu bude neúspešná, Fortuna vráti klientovi vklad do 24 hodín od vyhodnotenia stávky na hráčsky účet, maximálne však do sumy 30 (päťdesiat) eur.</li>
                              <li>Fortuna vráti klientovi len vklad za tiket, manipulačný poplatok vo výške 6 % z vkladu bude odvedený štátu (týka sa tiketov podaných na pobočkách).</li>
                              <li>Spoločnosť Fortuna si vyhradzuje právo kedykoľvek túto ponuku zmeniť, pozastaviť alebo zrušiť.</li>
                              <li>Spoločnosť Fortuna si vyhradzuje právo odobrať hráčovi naspäť pripísaný vklad v plnej výške, resp. požadovať jeho vrátenie, a to najmä v prípade, že hráč uviedol nesprávne údaje v registrácii, zatajil dôležité skutočnosti, alebo pri zistení, že si hráč otvoril viac hráčskych účtov.</li>
                              <li>Bonusová ponuka je obmedzená na jedného hráča a jednu IP adresu.</li>
                          </ul>
                      </div>
                      <div className={'list'}>
                          <h3 className={'list_title highlight'}>
                              Stávkový kredit až do výšky 30 eur
                          </h3>
                          <ul>
                              <li>Ponuka platí pre každého nového klienta, zaregistrovaného v stávkovej kancelárii FORTUNA SK, a.s. (ďalej Fortuna).</li>
                              <li>Klient získa vstupný bonus vo výške svojho prvého vkladu v rozpätí od 1 eur do 30 eur. Bonus je vyplácaný v klubových bodoch, ktoré môže následne využiť na stávkovanie alebo nákup kurzového zvýhodnenia.</li>
                              <li>Bonus sa aktivuje automaticky po vyplnení a odoslaní registračného formulára. Vklad je potrebné urobiť do 7 dní od aktivácie bonusu.</li>
                              <li>Od vkladu na hráčsky účet má klient 10 dní na splnenie podmienok pretáčania, po tejto dobe nárok na bonus stráca.</li>
                              <li>Bonus je vo výške 100% vkladu (pri vklade 30 eur je hodnota bonusu 30 eur – 25 000 klubových bodov), maximálna výška je 30 eur - 25 000 klubových bodov.</li>
                              <li>Pre získanie bonusu je potrebné uzavrieť stávky v hodnote výšky vkladu s kurzom 1,6 a vyšším (pri vklade 30 eur musíte pre získanie bonusu uzavrieť stávky za 30 eur s kurzom 1,6 a vyšším). Zarátavajú sa všetky tipy stávok okrem stávok na Virtuálne hry. Všetky stávková príležitosti na tikete musia byť vyhodnotená do 45 dní od podania tiketu. Predčasne vyplatené tikety sa do požadovaného obratu nezapočítavajú.</li>
                              <li>Bonus sa pripíše na hráčske konto automaticky, najneskôr do 24 hodín od podania tiketu.</li>
                              <li>Každý novo registrovaný klient má nárok na vstupný bonus len jedenkrát.</li>
                              <li>Spoločnosť Fortuna si vyhradzuje právo kedykoľvek túto ponuku zmeniť, pozastaviť alebo zrušiť.</li>
                              <li>Spoločnosť Fortuna si vyhradzuje právo odobrať hráčovi naspäť pripísaný vklad v plnej výške, resp. požadovať jeho vrátenie, a to najmä v prípade, že hráč uviedol nesprávne údaje v registrácii, zatajil dôležité skutočnosti, alebo pri zistení, že si hráč otvoril viac hráčskych účtov.</li>
                              <li>Bonusová ponuka je obmedzená na jedného hráča a jednu IP adresu.</li>
                          </ul>
                      </div>
                      <div className={'list'}>
                          <h3 className={'list_title highlight'}>
                              Bonus až 30 Free spinov do Casina
                          </h3>
                          <ul>
                               <li>Ponuka platí pre každého nového klienta, zaregistrovaného v stávkovej kancelárii FORTUNA SK, a.s. (ďalej Fortuna).</li>
                               <li>Klient získa vstupný bonus až 30 Free spinov v závislosti od výšky prvého vkladu. Za vklad do 10 eur (vrátane) získa 10 Free spinov, za vklad nad 10 eur získa 20 Free spinov, za vklad nad 20 eur získa 30 Free spinov, za vklad nad 30 eur získa 40 Free spinov a za vklad nad 40 eur získa 30 Free spinov.</li>
                               <li>Free spiny je možné využiť ako voľné zatočenia na hracích automatov v sekcii Casino. Hodnota Free spinu sa rovná minimálnej hodnote stávky na konkrétnej hre.</li>
                               <li>Bonus sa aktivuje automaticky po vyplnení a odoslaní registračného formulára. Vklad je potrebné urobiť do 7 dní od aktivácie bonusu.</li>
                               <li>Od vkladu na hráčsky účet má klient 10 dní na odohratie bonusu, po tejto dobe nárok na bonus stráca.</li>
                               <li>Bonus sa pripíše na hráčske konto automaticky.</li>
                               <li>Každý novo registrovaný klient má nárok na vstupný bonus len jedenkrát.</li>
                               <li>Spoločnosť Fortuna si vyhradzuje právo kedykoľvek túto ponuku zmeniť, pozastaviť alebo zrušiť.</li>
                               <li>Spoločnosť Fortuna si vyhradzuje právo odobrať hráčovi naspäť pripísaný vklad v plnej výške, resp. požadovať jeho vrátenie, a to najmä v prípade, že hráč uviedol nesprávne údaje v registrácii, zatajil dôležité skutočnosti, alebo pri zistení, že si hráč otvoril viac hráčskych účtov.</li>
                               <li>Bonusová ponuka je obmedzená na jedného hráča a jednu IP adresu.</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </main>
  )
}

export default Main;