import {environment} from '@/Helpers/enviroments';

const dev = {
    campaignId: 25,
    source: 'Fortuna Sport Sk Dev',
    // sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    sharpstarApiUrl: 'https://sharpstar-production.sv.workers.dev/',
    onesignalAppId: "4aee9612-829c-470f-b497-4b935fac6ecd",
    onesignalEnabled: true,
    sentryEnabled: true,
    fallbackUrl: 'https://example.com?refid1={refid1}_lp_shar_fail',
    cookieDomain: 'https://lpdev.sharpstar.pro/',
    modalsUrl: null,
    lang: null,
};

const prod = {
    campaignId: 25,
    source: 'Fortuna Sport Sk',
    // sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    sharpstarApiUrl: 'https://sharpstar-production.sv.workers.dev/',
    onesignalAppId: "3f1dc319-0eb0-4cf8-8801-68809157d734",
    onesignalEnabled: true,
    sentryEnabled: true,
    fallbackUrl: 'https://akunamapro.com/click?trvid=14804&var1={refid1}_lp_shar_fail&var2=&var3=&var4=&var6=',
    cookieDomain: 'https://lp.playonlinecasino.today',
    modalsUrl: null,
    lang: null,
};

let config;

if (environment() === 'production') {
    config = prod;
} else if (environment() === 'development'){
    config = dev;
} else {
    dev.onesignalAppId = '3d9fe1c2-a013-4cc7-be09-43b1a5c37e66';
    config = dev;
}

export default config;

