import React, { useRef } from 'react';
import useIsInViewport from "../../hooks/useIsInViewport";
import RegisterBtn from '../../assets/register-btn.svg';
import logo from "../../assets/logo.png";
import './style.scss';

const Header = ({ link }) => {
    const btnRef = useRef(null);
    const isInViewport = useIsInViewport(btnRef);

    return (
        <header>
            <div className={'header'}>
                <div className={'container'}>
                    <div className={'header_wrapper'}>
                        <a href={link} className={'logo'}>
                            <img src={logo} alt="logo"/>
                        </a>
                        <a href={link} className={'register'}>
                            Zaregistruj sa
                            <img
                                src={RegisterBtn}
                                className={'register-btn'}
                                alt="register btn"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className={'hero'}>
                <div className={'bg-img'} />
                <div className={'bg-player'} />
                <div className={'hero_text container'}>
                    <div className={'text-wrapper'}>
                        <h1 className={'title'}>
                            STAV Si ZA <span className={'highlight'}>30 € BEZ RIZIKA</span>
                            <br/>
                            A DÁME TI AJ <span className={'highlight'}>30 € KREDIT</span>
                        </h1>
                        <p className={'subtitle'}>
                            A <span className={'highlight'}>30 SPINOV!</span>
                        </p>
                        <a ref={btnRef} href={link} className={'button'}>CHCEM BONUS</a>
                    </div>
                </div>
            </div>

            <div className={'hints'}>
                <div className={'hints_wrapper container'}>
                    <a href={link} className={'hint_item'}>
                        <div className={'hint_text'}>
                            <p className={'highlight'}>Zaregistruj sa</p>
                            <p>jednoducho online</p>
                        </div>
                    </a>
                    <a href={link} className={'hint_item'}>
                        <div className={'hint_text'}>
                            <p className={'highlight'}>Získaj bonus</p>
                            <p>na svoj prvý vklad</p>
                        </div>
                    </a>
                    <a href={link} className={'hint_item'}>
                        <div className={'hint_text'}>
                            <p className={'highlight'}>Zabav sa</p>
                            <p>hraním s Fortunou</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className={`drawer ${isInViewport ? 'hidden' : ''}`}>
                <a href={link} className={'button'}>CHCEM BONUS</a>
            </div>
        </header>
    )
}

export default Header;
