import React from 'react';
import * as Sentry from "@sentry/browser";
import SharpstarServiceHandler from "App/Services/Handlers/SharpstarServiceHandler";
import NotificationService from "App/Services/NotificationService";
import CookieService from "App/Services/CookieService";
import SentryConfig from "App/Helpers/SentryConfig";
import UrlParams from "App/Helpers/UrlParams";
import { getLocalStorageVal } from "App/Helpers/getLocalStorageVal";
import { isLocal, environment } from "App/Helpers/enviroments";
import config from "Pages/aq/sport/sk/fortuna/config";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import "./main.scss";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            offerId: null,
            redirectLink: null,
        };

        this.setUrlParam = this.setUrlParam.bind(this);
        this.setUrlParamValues(); // Set refids, hash, rid
        this.initServices(); //Init analytics
    }

    setUrlParam(name) {
        if (this[name] !== '0') { // If exists save it to local storage.
            localStorage.setItem(name, this[name]);
        } else if (getLocalStorageVal(name)) {  // Else get from local storage and set
            this[name] = getLocalStorageVal(name);
        }
    }

    setUrlParamValues() {
        this.refid3 = UrlParams.refid3;
        this.refid2 = UrlParams.refid2;
        this.refid1 = UrlParams.refid1;
        this.hash = UrlParams.hash;
        this.rid = UrlParams.rid;

        this.setUrlParam('refid3');
        this.setUrlParam('refid2');
        this.setUrlParam('refid1');
        this.setUrlParam('hash');
        this.setUrlParam('rid');
    }

    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.source,
            config.campaignId,
            new CookieService(config.cookieDomain)
        );

        SentryConfig.init(
            config.sentryEnabled,
            null,
            config.source,
            config.campaignId,
            null,
            document.location.host,
            environment(),
            this.refid3
        );

        if (window.OneSignal !== undefined) {
            NotificationService.initOneSignal(config.onesignalAppId, isLocal(), true)
                .then(() => {
                    NotificationService.onSubscriptionChange()
                        .then(async (playerId) => {
                            try {
                                const offerId = this.state.offerId ?? localStorage.getItem('offerId');

                                await this.sharpstarDataService.createPushLead(playerId, offerId, this.rid);
                            } catch (e) {
                                Sentry.captureException(e);
                            }
                    })
                        .then(() => window.OneSignal.sendTag("refid3", this.refid3))
                        .catch((e) => {
                            Sentry.captureException(e);
                        });
                })
                .catch((e) => {
                    Sentry.withScope(function(scope) {
                        scope.setTag("NotificationService", "InitOneSignal");
                        Sentry.captureException(e);
                    });
                });
        }
    }

    async componentDidMount() {
        this.sharpstarDataService.getOffers(undefined, 0, this.rid)
            .then((res) => {
                this.setState({
                    offerId: res.offers[0].id,
                    redirectLink: res.offers[0].link
                });

                localStorage.setItem('offerId', res.offers[0].id);
            })
            .catch((e) => {
                Sentry.captureException(e);
            });

        NotificationService.showPrompt();
    }

    render() {
        return (
            <div className={'app-wrapper'}>
                <Header
                    link=
                        {
                            this.state.redirectLink ||
                            config.fallbackUrl.replace('{refid1}', this.refid1)
                        }
                />
                <Main />
                <Footer />
            </div>
        );
    }
}

export default App;